<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
              ><i class="fa fa-home"></i
            ></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'GroupbuyingsList' }"
              >團購管理
</b-breadcrumb-item
            >
            <b-breadcrumb-item active>門市庫存品開團</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card class="pb-lg-0 pb-5">
      <section class="row">
        <div class="col-8 h5">編輯草稿</div>
        <div class="col-4 h5">
          <button class="btn btn-outline-danger" @click="handleDelete">
            刪除
          </button>
        </div>
        <div class="col-12 col-xl-8">
          <b-form-group
            v-if="false"
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="選擇分店"
            label-for="input-1"
          >
            <div>
              <b-spinner
                small
                v-if="isFetchFirstLayerBranches"
                variant="secondary"
              ></b-spinner>

              <b-form-select
                v-else
                class="form-control form-control-sm col-12 col-xl-6"
                v-model="firstBranch"
                :state="v$.form.branch_id.$error ? false : null"
                @change="handleFirstBranch"
              >
                <b-form-select-option :value="null" disabled>
                  請選擇分店
                </b-form-select-option>
                <b-form-select-option
                  v-for="branch in mapFirstLayerBranches"
                  :key="branch.id"
                  :value="branch"
                >
                  {{ branch.name }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div>
              <b-spinner
                small
                v-if="isFetchSecondLayerBranches"
                variant="secondary"
              ></b-spinner>

              <b-form-select
                v-if="
                  mapSecondLayerBranches && mapSecondLayerBranches.length > 1
                "
                class="form-control form-control-sm col-12 col-xl-6 mt-2"
                v-model="secondBranch"
                :state="v$.form.branch_id.$error ? false : null"
                @change="handleSecondBranch"
              >
                <b-form-select-option :value="null" disabled>
                  請選擇分店
                </b-form-select-option>
                <b-form-select-option
                  v-for="branch in mapSecondLayerBranches"
                  :key="branch.id"
                  :value="branch"
                >
                  {{ branch.name }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <div>
              <b-spinner
                small
                v-if="isFetchThirdLayerBranches"
                variant="secondary"
              ></b-spinner>

              <b-form-select
                v-if="mapThirdLayerBranches && mapThirdLayerBranches.length > 1"
                class="form-control form-control-sm col-12 col-xl-6 mt-2"
                v-model="thirdBranch"
                :state="v$.form.branch_id.$error ? false : null"
                @change="handleThirdBranch"
              >
                <b-form-select-option :value="null" disabled>
                  請選擇分店
                </b-form-select-option>
                <b-form-select-option
                  v-for="branch in mapThirdLayerBranches"
                  :key="branch.id"
                  :value="branch"
                >
                  {{ branch.name }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <b-form-invalid-feedback :state="!v$.form.branch_id.$error">
              此欄位為必填
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form-select
              class="form-control form-control-sm col-12 col-xl-6"
              v-model="currentProductId"
              @change="handleChangeProduct"
              :disabled="!productOptions || productOptions.length === 0"
            >
              <b-form-select-option
                v-if="productOptions && productOptions.length > 1"
                :value="null"
                disabled
              >
                請選擇商品名稱
              </b-form-select-option>
              <b-form-select-option
                v-for="productOption in productOptions"
                :key="productOption.id"
                :value="productOption.id"
              >
                {{ productOption.title }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-spinner
            style="width: 2rem; height: 2rem;"
            v-if="isFetchProductInfo"
            variant="secondary"
          ></b-spinner>
          <div class="mb-4 product-info" v-if="showProductInfo">
            <div>店內碼: {{ productInfo.barcode }}</div>
            <div>國際碼: {{ productInfo.ian }}</div>
            <div>原價: {{ productInfo.orig_price }}</div>
            <div>售價: {{ productInfo.sale_price }}</div>
          </div>

          <b-form-group
            label-cols="7"
            label-cols-lg="2"
            label-size="sm"
            label="庫存共: "
            label-for="total_sku_quantity"
          >
            <b-form-input
              id="total_sku_quantity"
              class="mb-2 mr-sm-2 mb-sm-0"
              type="number"
              style="width: 80px"
              v-model="form.total_sku_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.total_sku_quantity.$error ? false : null"
              @click="handleSelect"
            ></b-form-input>

            件
            <b-form-invalid-feedback :state="!v$.form.total_sku_quantity.lengthSix.$invalid">
                數字上限為六位數
            </b-form-invalid-feedback>

            <!-- <b-form-invalid-feedback
              :state="!v$.form.total_sku_quantity.less.$invalid"
            >
              msg for 庫存
            </b-form-invalid-feedback> -->
          </b-form-group>

          <b-form-group
            v-if="currentProductId"
            label-cols="12"
            label-cols-lg="2"
            content-cols-xl="7"
            label-size="sm"
            label="開團群組"
            label-for="input-1"
          >
            <template v-if="product">
              <b-spinner
                small
                v-if="isFetchGroup"
                variant="secondary"
              ></b-spinner>

              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="form.groupbuying_ids"
              >
                <b-form-checkbox
                  class="mb-lg-2 mb-0"
                  v-for="groupbuying in product.groupbuyings"
                  :key="groupbuying.id"
                  :value="groupbuying.id"
                  @change="handleGroupOptionChange(groupbuying.group)"
                >
                  <div class="row">
                    <div class="col-12 col-lg-6 mb-2 mb-xl-0">
                      {{ groupbuying.group.name }}
                    </div>
                    <div class="col-lg-6 text-center">
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="number"
                        style="width: 80px"
                        v-model="groupbuying.sku_quantity"
                        :disabled="
                          !form.groupbuying_ids.includes(groupbuying.id)
                        "
                        :formatter="
                          (value) => (value < 1 ? 0 : Math.round(value))
                        "
                        @click="handleSelect"
                        @change="handleGroupChange"
                        :state="(v$.form.sku_quantities.$error &&
                          form.groupbuying_ids.includes(groupbuying.id) &&
                          groupbuying.sku_quantity > 999999) ? false : null"
                      ></b-form-input>
                      件

                      <b-form-invalid-feedback v-if="form.groupbuying_ids.includes(groupbuying.id) && groupbuying.sku_quantity > 999999" :state="!v$.form.sku_quantities.lengthSix.$invalid">
                          數字上限為六位數
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-checkbox>
              </b-form-checkbox-group>

              <b-form-invalid-feedback :state="!v$.form.group_ids.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </template>
          </b-form-group>

          <b-form-group
            label-cols="8"
            label-cols-lg="2"
            label-size="sm"
            label="每人最高購買數"
            label-for="once_max_quantity"
          >
            <b-form-input
              id="once_max_quantity"
              class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
              type="number"
              v-model="form.once_max_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.once_max_quantity.$error ? false : null"
              @click="handleSelect"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_max_quantity.less.$invalid"
            >
              需小於商品總數上限
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_max_quantity.lengthSix.$invalid"
            >
              數字上限為六位數
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols="8"
            label-cols-lg="2"
            label-size="sm"
            label="每人最低購買數"
            label-for="once_quantity"
          >
            <b-form-input
              id="once_quantity"
              class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
              type="number"
              v-model="form.once_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.once_quantity.$error ? false : null"
              @click="handleSelect"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_quantity.less.$invalid"
            >
              需小於每人最高購買數
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols="8"
            label-cols-lg="2"
            label-size="sm"
            label="下單數量遞增數"
            label-for="once_increase_quantity"
          >
            <b-form-input
              id="once_increase_quantity"
              class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
              type="number"
              v-model="form.once_increase_quantity"
              :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
              :state="v$.form.once_increase_quantity.$error ? false : null"
              @click="handleSelect"
              :disabled="!canEditOnceIncreaseQuantity"
            ></b-form-input>
            <b-form-invalid-feedback
              class="invalid-wording-right"
              :state="!v$.form.once_increase_quantity.less.$invalid"
            >
              下單遞增數與最低購買數加總不可大於最高購買數
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="開始時間"
            label-for="input-1"
          >
            <div class="d-flex">
              <div class="w-50">
                <datepicker
                  class="mb-2"
                  placeholder="Select Date"
                  v-model="form.start_at.date"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :disabledDates="disabledDates"
                ></datepicker>
                <b-form-invalid-feedback
                  :state="!v$.form.start_at.date.required.$error"
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </div>

              <div class="ml-2 w-50">
                <vue-timepicker
                  format="HH:mm"
                  v-model="form.start_at.time"
                  :input-class="['form-control']"
                ></vue-timepicker>
                <b-form-invalid-feedback
                  :state="
                    !v$.form.start_at.time.HH.$error ||
                    !v$.form.start_at.time.mm.$error
                  "
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            label="截止時間"
            label-for="input-1"
            description="*達截止時間系統將自動結團"
          >
            <div class="d-flex">
              <div class="w-50">
                <datepicker
                  class="mb-2"
                  placeholder="Select Date"
                  v-model="form.end_at.date"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  :input-class="{
                    'is-invalid': v$.form.start_at.$invalid,
                  }"
                  :disabledDates="disabledDates"
                ></datepicker>
                <b-form-invalid-feedback :state="!v$.form.end_at.date.$error">
                  此欄位為必填
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  :state="!v$.form.start_at.date.beforeEndAt.$invalid"
                >
                  截止時間必須大於開團時間
                </b-form-invalid-feedback>
              </div>

              <div class="ml-2 w-50">
                <vue-timepicker
                  :input-class="[
                    'form-control',
                    {
                      'is-invalid':
                        v$.form.start_at.time.HH.$error ||
                        v$.form.start_at.time.mm.$error ||
                        v$.form.start_at.date.beforeEndAt.$invalid,
                    },
                  ]"
                  v-model="form.end_at.time"
                ></vue-timepicker>
                <b-form-invalid-feedback
                  :state="
                    !v$.form.end_at.time.HH.$error ||
                    !v$.form.end_at.time.mm.$error
                  "
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            label-cols="6"
            label-cols-lg="2"
            label-size="sm"
            label="員工編號"
            label-for="tracking_code"
          >
            <b-form-input
              id="tracking_code"
              class="mb-2 mr-sm-2 mb-sm-0"
              style="width: 170px"
              v-model="form.tracking_code"
              :formatter="
                (value) => (value.length > 6 ? value.slice(0, 6) : value)
              "
              :state="v$.form.tracking_code.$error ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="v$.form.tracking_code.required.$invalid"
            >
              此欄位為必填
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="v$.form.tracking_code.lengthSix.$invalid"
            >
              需為6碼數字
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label-cols="6"
            label-cols-lg="2"
            label-size="sm"
            label="順序"
            label-for="order"
          >
            <b-form-input
              id="order"
              class="mb-2 mr-sm-2 mb-sm-0"
              style="width: 170px"
              v-model="form.order"
              :state="v$.form.order.$error ? false : null"
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="v$.form.order.required.$invalid"
            >
              此欄位為必填
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="v$.form.order.lengthTwo.$invalid"
            >
              需為2碼數字
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </section>

      <div style="color:#e06666;padding:20px 0px;font-size:14px;">* 因 LINE 系統限制，請留意同期間開團數不得超過50個團購</div>

      <div class="d-flex justify-content-center buttons__action">
        <b-button
          class="mr-3"
          variant="success"
          @click="saveAndBackToUpdateImages"
          :disabled="!form.branch_id"
          >設定<br class="d-lg-none" />商品圖片</b-button
        >
        <b-button
          class="mr-3"
          variant="success"
          @click="saveDraft"
          :disabled="!form.branch_id"
          >儲存草稿</b-button
        >
        <b-button variant="success" @click="save" :disabled="!form.branch_id || isSaving"
          >確認開團</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { format, isBefore, set, subDays } from "date-fns";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import { zh } from "vuejs-datepicker/dist/locale";
import branchApi from "@/apis/branch";
import groupApi from "@/apis/group";
import groupbuyApi from "@/apis/groupbuy";
import productApi from "@/apis/product";
import _ from "lodash";

export default {
  components: { Datepicker, VueTimepicker },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data: () => {
    return {
      zh,
      currentProductId: null,
      product: null,
      productOptions: null,
      groupOptions: null,
      firstBranch: null,
      secondBranch: null,
      thirdBranch: null,
      firstLayerBranches: [],
      secondLayerBranches: [],
      thirdLayerBranches: [],
      isFetchFirstLayerBranches: false,
      isFetchSecondLayerBranches: false,
      isFetchThirdLayerBranches: false,
      isFetchGroup: false,
      canDivideTotal: false, // 用來決定是否可算分組組數,
      disabledDates: { to: subDays(new Date(), 1) },
      form: {
        branch_id: null,
        // group_id: null,
        group_ids: [],
        groupbuying_ids: [],
        product_ids: null,
        total_sku_quantity: null,
        sku_quantities: [],
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 購買遞增數
        // is_force_confirmed: false, // 強制結單
        max_quantity: 0, // 商品總數上限
        tracking_code: null,
        // group_type_ids: [],
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        // payday_period: 0,
        order: "50",
      },
      all_groupbuying_ids: [],
      maxSkuQuantity: 0, //用做最高購買數不可大於最高群組配量的參考
      minSkuQuantity: 0, //用做最低購買數不可大於最低群組配量的參考
      canEditOnceIncreaseQuantity: false,
      isUpdatedOnceMaxQuantity: false, // flag: 用來判斷是否已經改過OnceMaxQuantity
      isSaving: false,
      productInfo: {
        id: null,
        name: null,
        barcode: null, // 店內碼
        ian: null, // 國際碼
        rating: null, // 批價
        bargain_price: null, // 特價
        unit_price: null, // 單價
        discount: null, // 組合優惠
      },
      showProductInfo: false,
      isFetchProductInfo: false,
    };
  },
  validations() {
    return {
      form: {
        branch_id: { required },
        group_ids: { required },
        sku_quantities: {
          lengthSix: (value) => {
            return value.filter((v) => { return v > 999999 }).length == 0
          },
        },
        total_sku_quantity: {
          required,
          lengthSix: () => {
            return (
              Number(this.form.total_sku_quantity) <= 999999
            );
          },
        },
        once_max_quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >=
                  Number(this.form.once_max_quantity)
              : true;
          },
          lengthSix: () => {
            return (
              Number(this.form.once_max_quantity) <= 999999
            );
          },
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: {
          required,
          less: () => {
            if (+this.form.once_max_quantity === +this.form.once_quantity) {
              return true;
            }
            return +this.form.once_increase_quantity + +this.form.once_quantity <= +this.form.once_max_quantity;
          },
        },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        tracking_code: {
          required,
          lengthSix: () => {
            if (!this.form.tracking_code) return true;
            return (
              this.form.tracking_code.length === 6 &&
              /^[0-9]*$/.test(this.form.tracking_code)
            );
          },
        },
        order: {
          required,
          lengthTwo: () => {
            if (!this.form.order) return true;
            return (
              this.form.order.length <= 2 &&
              /^[0-9]*$/.test(this.form.order)
            );
          },
        },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),
    mapFirstLayerBranches() {
      return this.mapBranches(this.firstLayerBranches);
    },
    mapSecondLayerBranches() {
      return this.mapBranches(this.secondLayerBranches);
    },
    mapThirdLayerBranches() {
      return this.mapBranches(this.thirdLayerBranches);
    },
  },
  created: async function () {
    this.currentProductId = this.$route.params.id;
    this.form.product_ids = [this.$route.params.id];
    await this.getFirstLayerBranches();

    if (!this.form.branch_id) {
      this.$swal.fire({
        title: "錯誤",
        type: "error",
        text: "請先選擇分店",
      });
      return;
    }
  },
  watch: {
    "form.branch_id": async function (val) {
      if (val) {
        await this.getGroups();
      }
    },
    groupOptions: function (val) {
      if (!val) {
        return;
      }

      let me = this;
      this.getUngroupbuyingProducts().then(() => {
        me.$nextTick(() => {
          //with this we skip the first change
          me.canDivideTotal = true;
        });
      });
    },
    "form.total_sku_quantity": function (val) {
      if (!this.canDivideTotal) {
        return;
      }

      this.divideTotalSku(val);
    },
    "form.groupbuying_ids": function (newVal, oldVal) {
      if (!this.all_groupbuying_ids) {
        return;
      }

      // prevent infinite loop
      if (newVal.length == oldVal.length) {
        return;
      }

      // 確保groupbuying_ids 順序維持api 來的順序, 避免執行 divideTotalSku, 把餘數加錯團
      let result = [];
      for (let i = 0; i < this.all_groupbuying_ids.length; i++) {
        if (!newVal.includes(this.all_groupbuying_ids[i])) {
          continue;
        }

        result.push(this.all_groupbuying_ids[i]);
      }

      this.form.groupbuying_ids = result;
    },
    currentProductId(val) {
      // 切換商品時, 不要讓前端自行計算庫存分組數
      this.canDivideTotal = false;

      this.form.product_ids = [val];
      if (this.productOptions) {
        this.product = this.productOptions.find((p) => p.id == val);

        if (!this.product) {
          if (val) {
            this.$swal("失敗", '查無此商品', "error").then(() => {
              this.$router
                .push({ name: "StoreGroupbuyingDraft" })
                .catch(() => {});
            });
          }
          return;
        }

        if (!this.product.is_enabled) {
          this.$swal("失敗", '此商品已停用', "error").then(() => {
              this.$router
                .push({ name: "StoreGroupbuyingDraft" })
                .catch(() => {});
            });
          return;
        }

        // data from backend model to frontend model
        if (
          this.product &&
          this.product.groupbuyings &&
          this.product.groupbuyings.length > 0
        ) {
          this.form.groupbuying_ids = this.product.groupbuyings
            .map((groupbuying) => {
              if (groupbuying.status == "80") {
                return groupbuying.id;
              }
            })
            .filter((id) => id);

          this.all_groupbuying_ids = this.product.groupbuyings.map(
            (groupbuying) => groupbuying.id
          );

          this.product.groupbuyings.map((groupbuying) => {
            groupbuying.sku_quantity = groupbuying.max_quantity;
          });

          // data from backend model to frontend model
          // 2022-09-30 補：且要找出 groupbuyings.status 為 80 的
          this.form.total_sku_quantity = this.product.skus[0].max_quantity;
          this.form.once_max_quantity =
            this.product.groupbuyings.find((groupbuying) => groupbuying.status == 80).once_max_quantity;
          this.form.once_quantity =
            this.product.groupbuyings.find((groupbuying) => groupbuying.status == 80).once_quantity;
          this.form.once_increase_quantity =
            this.product.groupbuyings.find((groupbuying) => groupbuying.status == 80).once_increase_quantity;
          this.form.tracking_code =
            this.product.groupbuyings.find((groupbuying) => groupbuying.status == 80).tracking_code;

          // 開團時間若是過去時間要防呆（叫出草稿時，若開團日期＆截止日期 < 今天日期，就把日期改成今天日期 ）
          const startOfToday = new Date().setHours(0, 0, 0, 0);
          let startAt = new Date(this.product.groupbuyings[0].start_at);
          startAt = startAt > startOfToday ? startAt : startOfToday;
          this.form.start_at.date = startAt;
          this.form.start_at.time.HH = String(getHours(startAt)).padStart(
            2,
            "0"
          );
          this.form.start_at.time.mm = String(getMinutes(startAt)).padStart(
            2,
            "0"
          );

          const endOfToday = new Date().setHours(23, 59, 59, 999);
          let endAt = new Date(this.product.groupbuyings[0].end_at);
          endAt = endAt > startAt ? endAt : endOfToday;
          this.form.end_at.date = endAt;
          this.form.end_at.time.HH = String(getHours(endAt)).padStart(2, "0");
          this.form.end_at.time.mm = String(getMinutes(endAt)).padStart(2, "0");

          this.maxSkuQuantity =
            _.maxBy(this.product.groupbuyings, function (g) {
              return g.status == "80" ? g.sku_quantity : null;
            }).sku_quantity ?? 0;
          this.maxSkuQuantity = parseInt(this.maxSkuQuantity);

          this.minSkuQuantity =
            _.minBy(this.product.groupbuyings, function (g) {
              return g.status == "80" ? g.sku_quantity : null;
              // return g.sku_quantity;
            }).sku_quantity ?? 0;
          this.minSkuQuantity = parseInt(this.minSkuQuantity);

          this.form.order = String(this.product.groupbuyings[0].order) ?? "50"

          if (val) {
            this.$router
              .push({
                name: "StoreGroupbuyingDraft",
                query: { product_id: val },
              })
              .catch(() => {});
          }

          let me = this;
          this.$nextTick(function () {
            me.canDivideTotal = true;
          });
          this.updateProductInfo(this.product);
          this.updateGroupSkuQuantity()
        }
      }
    },

    /*
     * 每人最高購買數要跟著最大的件數跑沒錯, 但初始化頁面時, 不要更改到form.once_max_quantity
     *  初始化的 maxSkuQuantity 會從 0 設定成 [商品中第一份草稿的once_max_quantity], 所以會進這個function
     *  故多給一個flag (isUpdatedOnceMaxQuantity), 用來判斷是否已經經過首次的變動
     *  用來解決若是當件數真的被減到0, 又開始加回來, 能正常讓每人最高購買數要跟著最大的件數跑
     */
    maxSkuQuantity: function (newVal, oldVal) {
      if ((oldVal == 0 && newVal) && !this.isUpdatedOnceMaxQuantity) {
        return;
      }

      this.form.once_max_quantity = this.maxSkuQuantity;
      this.isUpdatedOnceMaxQuantity = true;
    },
    "form.once_max_quantity": function () {
      this.handleCanEditOnceIncreaseQuantity();
    },
    "form.once_quantity": function () {
      this.handleCanEditOnceIncreaseQuantity();
    },
    "form.order": function () {
      this.form.order = this.form.order.replace(/^0+(?=\d)/, "");

      // 確保輸入的值為數字，如果不是，則設置為空字串
      const parsedValue = parseFloat(this.form.order);
      if (isNaN(parsedValue)) {
        this.form.order = "";
      }
    }
  },
  methods: {
    handleCanEditOnceIncreaseQuantity () {
      if (parseInt(this.form.once_max_quantity) != parseInt(this.form.once_quantity)) {
        this.canEditOnceIncreaseQuantity = true;
      }

      if (parseInt(this.form.once_max_quantity) == parseInt(this.form.once_quantity)) {
        this.canEditOnceIncreaseQuantity = false;
        this.form.once_increase_quantity = 1;
      }
    },

    handleGroupOptionChange() {
      if (this.product == undefined) {
        return;
      }

      this.divideTotalSku(this.form.total_sku_quantity);
    },

    handleGroupChange() {
      if (!this.product) {
        return;
      }

      let me = this;

      this.maxSkuQuantity =
      _.chain(this.product.groupbuyings)
      .filter(function (g) {
        if (me.form.groupbuying_ids.includes(g.id)) {
          return g;
        }
      })
      .maxBy(function (g) {
        return parseInt(g.sku_quantity);
      })
      .value()
      .sku_quantity ?? 0;
      this.maxSkuQuantity = parseInt(this.maxSkuQuantity);

      this.minSkuQuantity =
      _.chain(this.product.groupbuyings)
      .filter(function (g) {
        if (me.form.groupbuying_ids.includes(g.id)) {
          return g;
        }
      })
      .minBy(function (g) {
        return parseInt(g.sku_quantity);
      })
      .value()
      .sku_quantity ?? 0;
      this.minSkuQuantity = parseInt(this.minSkuQuantity);

      let total = 0;
      for (let i = 0; i < this.product.groupbuyings.length; i++) {
        if (
          !this.form.groupbuying_ids.includes(this.product.groupbuyings[i].id)
        ) {
          continue;
        }

        total += parseInt(this.product.groupbuyings[i].sku_quantity);
      }

      this.canDivideTotal = false;
      this.form.total_sku_quantity = total;
      this.updateGroupSkuQuantity()

      this.$nextTick(function () {
        me.canDivideTotal = true;
      });
    },

    handleSelect(e) {
      e.target.select();
    },

    divideTotalSku(total) {
      if (!this.canDivideTotal) {
        return;
      }

      const groupCount = this.form.groupbuying_ids.length;
      if (total <= 0 || groupCount <= 0) {
        return;
      }

      const count = Math.floor(total / groupCount);
      const mod = total % groupCount;

      this.product.groupbuyings.forEach((groupbuying) => {
        if (!this.form.groupbuying_ids.includes(groupbuying.id)) {
          groupbuying.sku_quantity = 0;
          return;
        }

        if (groupbuying.id == this.form.groupbuying_ids[0]) {
          groupbuying.sku_quantity = count + mod;
          return;
        }

        groupbuying.sku_quantity = count;
      });

      this.minSkuQuantity = count;
      this.maxSkuQuantity = count + mod;

      this.updateGroupSkuQuantity()
      this.$forceUpdate(); // 否則dom 不會更新
    },

    updateGroupSkuQuantity () {
      let me = this;
      const skuQuantities = this.form.groupbuying_ids.map(function (id) {
        const groupbuying = me.product.groupbuyings.find((g) => g.id == id);
        return +groupbuying.sku_quantity;
      });
      this.form.sku_quantities = skuQuantities
    },

    async getUngroupbuyingProducts() {
      try {
        const { data } = await productApi.getUngroupbuyingProducts({
          branch_id: this.form.branch_id,
        });

        this.productOptions = data.data;

        if (this.$route.query.product_id) {
          this.currentProductId = this.$route.query.product_id;
        }
      } catch (err) {
        console.log(err);
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "查無未開團商品資料",
        });
      }
    },
    async save() {
      const result = await this.v$.$validate();
      console.log(this.v$.$errors);
      if (!result) {
        return;
      }

      const form = this.prepareForm();

      // 若有群組可賣數 =0，必須提示擋住不能送出
      console.log(form);

      if (form.sku_quantities.includes(0)) {
        this.$swal("失敗", "群組可賣量必須大於0", "error");
        return;
      }

      if (this.form.once_max_quantity > this.maxSkuQuantity) {
        this.$swal("失敗", "最高購買數不可大於最高群組配量", "error");
        return;
      }

      if (this.form.once_quantity > this.minSkuQuantity) {
        this.$swal("失敗", "最低購買數不可大於最低群組配量", "error");
        return;
      }

      // 開團時，『最高購買數』、『最低購買數』、『數量遞增數』皆需 > = 1
      if (! (parseInt(this.form.once_max_quantity) >= 1 && parseInt(this.form.once_quantity) >= 1 && parseInt(this.form.once_increase_quantity) >= 1)) {
        this.$swal("失敗", "開團時，『最高購買數』、『最低購買數』、『數量遞增數』皆需大於等於1", "error");
        return;
      }

      // if (
      //   parseInt(this.form.once_increase_quantity) +
      //     parseInt(this.form.once_quantity) >
      //   parseInt(this.form.once_max_quantity)
      // ) {
      //   this.$swal(
      //     "失敗",
      //     "下單遞增數與最低購買數加總不可大於最高購買數",
      //     "error"
      //   );
      //   return;
      // }

      if (form.sku_quantities.length <= 0) {
        this.$swal(
          "失敗",
          "請填寫庫存組數, 且庫存數必須等於所有團的組數加總",
          "error"
        );
        return;
      }

      if (this.product.images.length < 2) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "最少需上傳 2 張商品圖片",
        });
        return;
      }
      this.isSaving = true;
      try {
        const { data } = await groupbuyApi.updateDraftGroupbuy(form);

        const ids = data.data.map((groupbuying) => {
          if (groupbuying.status == '80') {
            return groupbuying.id;
          }
        });

        // 開始前，先檢查是否可以開團
        let checkResponse = await groupbuyApi.checkBeforeStart({
          ids: _.compact(ids),
        });

        if (
          checkResponse.data.status === 'failed' &&
          checkResponse.data.data.mask_image_check === false
        ) {
          // 跳出提示
          let result = await this.$swal.fire({
            type: "warning",
            title: "提醒",
            text: "此草稿套框因行銷活動更新已無法使用，請重新設定。",
            confirmButtonText: "直接使用<br />新版套框",
            cancelButtonText: "返回編輯<br />商品圖片",
            confirmButtonColor: "rgb(255, 118, 116)",
            cancelButtonColor: "rgb(65, 184, 130)",
            showCancelButton: true,
          });

          // 依照 confirm 結果判斷是要繼續開團還是返回。
          if (!result.value) {
            this.saveAndBackToUpdateImages();
            return;
          }
        }

        const payload = {
          ids: _.compact(ids),
          branch_id: this.form.branch_id,
          once_max_quantity: this.form.once_max_quantity,
        };

        await groupbuyApi.startGroupbuy(payload);

        this.$swal.fire({
          type: "success",
          title: "開團成功",
        });

        this.$router.push({
          name: "GroupbuyingsList",
          query: {
            tab: "going",
          },
        });
      } catch (err) {
        let errMsg = '開團失敗';
        if (err.response && err.response.data.message) {
          errMsg = err.response.data.message;
        } else {
          console.error(err);
        }
        this.$swal("失敗", ` ${errMsg}`, "error");
      }
      this.isSaving = false;
    },
    async saveDraft() {
      if (!this.product) {
        this.$swal("失敗", "請填寫草稿內容", "error");
        return;
      }

      const form = this.prepareForm();
      console.log(form)

      await this.updateDraftGroupbuy(form);

      // 重撈一次本頁主要資料, 避免切換商品又切回來卻沒有顯示剛剛更新過的資訊
      this.getUngroupbuyingProducts();
    },
    async updateDraftGroupbuy(form) {
      try {
        await groupbuyApi.updateDraftGroupbuy(form);
        this.$swal.fire({
          icon: "success",
          text: "編輯草稿成功",
        });
      } catch (err) {
        console.log(err);
        this.$swal("失敗", err.response.data.message, "error");
      }
    },
    async getGroups() {
      try {
        this.isFetchGroup = true;
        const { data } = await groupApi.getGroups({
          status: 1,
          branch_id: this.form.branch_id,
        });

        if (data.data.length === 0) {
          this.$swal("失敗", "此分店無相關群組", "error");
          this.isFetchGroup = false;
          return;
        }

        this.groupOptions = data.data;
        this.form.group_ids = this.groupOptions.map((group) => group.id); // 預設把所有群組都打勾

        this.groupOptions.forEach((groupOpt) => {
          groupOpt.sku_quantity = 0; // 初始化平攤庫存為0
        });
      } catch (err) {
        console.log(err);
      }
      this.isFetchGroup = false;
    },
    async getFirstLayerBranches() {
      this.isFetchFirstLayerBranches = true;
      const branches = await this.getBelowBranches(this.currentBranch.id);
      this.firstLayerBranches = branches;
      if (branches.length > 1) {
        this.firstLayerBranches = branches.filter(
          (branch) => branch.id !== this.currentBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.firstBranch = this.mapFirstLayerBranches[0];
        this.form.branch_id = this.currentBranch.id;
      }

      this.isFetchFirstLayerBranches = false;
    },
    async handleFirstBranch(branch) {
      this.isFetchSecondLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      if (branches.length > 1) {
        this.secondLayerBranches = branches.filter(
          (branch) => branch.id !== this.firstBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.secondLayerBranches = [];
        this.form.branch_id = branch.id;
      }
      this.isFetchSecondLayerBranches = false;
    },
    async handleSecondBranch(branch) {
      this.isFetchThirdLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      this.thirdLayerBranches = branches;
      if (branches.length > 1) {
        this.thirdLayerBranches = branches.filter(
          (branch) => branch.id !== this.secondBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.form.branch_id = branch.id;
      }
      this.isFetchThirdLayerBranches = false;
    },
    async getBelowBranches(branch_id) {
      try {
        const { data } = await branchApi.getCurrentBelowBranches({
          branch_id,
        });

        return data.data.filter(
          (branch) => branch.org_id === this.organization.id
        );
      } catch (error) {
        this.$swal("失敗", error.response.data.message, "error");
        return;
      }
    },
    mapBranches(branches) {
      return branches
        .sort((a, b) => a.level - b.level)
        .sort((a, b) => {
          // if "level" is the same, order by "order"
          if (a.level === b.level) {
            return a.order - b.order;
          }
        })
        .map((branch) => {
          let branchName = `${branch.branch_code} ${branch.name}`;
          if (branch.level === 2) {
            branchName = `- ${branch.branch_code} ${branch.name}`;
          }
          if (branch.level === 3) {
            branchName = `- - ${branch.branch_code} ${branch.name}`;
          }

          return {
            ...branch,
            value: branch.id,
            name: branchName,
          };
        });
    },
    handleChangeProduct(event) {
      const selectedProduct = this.productOptions.find(
        (opt) => opt.id == event
      );

      if (!selectedProduct) {
        return;
      }

      // 選擇商品後, 如果商品還沒有團購, 就轉到新增設定開團資料畫面
      if (selectedProduct.groupbuyings.length <= 1) {
        this.$router.push({
          name: "StoreGroupbuyingCreate",
          params: {
            branch_id: this.form.branch_id,
            id: event,
          },
        });
      }
    },

    prepareForm() {
      let me = this;
      const skuQuantities = this.form.groupbuying_ids.map(function (id) {
        const groupbuying = me.product.groupbuyings.find((g) => g.id == id);
        return +groupbuying.sku_quantity;
      });

      const form = {
        ...this.form,
        sku_quantities: skuQuantities,
        start_at: format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
        end_at: format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
      };

      return form;
    },

    async saveAndBackToUpdateImages() {
      if (!this.currentProductId || !this.form.branch_id) {
        this.$swal("失敗", "請先選擇商品及分店", "error");
        return;
      }

      const form = this.prepareForm();

      try {
        await groupbuyApi.updateDraftGroupbuy(form);
        // this.$swal.fire({
        //   icon: "success",
        //   text: "編輯草稿成功",
        // });

        this.$router.push({
          name: "StoreGroupbuyingUpdateImage",
          params: {
            branch_id: this.form.branch_id,
            id: this.currentProductId,
          },
        });
      } catch (err) {
        console.log(err);
        this.$swal("失敗", err.response.data.message, "error");
      }
    },

    async handleDelete() {
      if (!this.currentProductId) {
        this.$swal("失敗", "請先選擇商品", "error");
        return;
      }

      this.$swal({
        title: "<strong>你確定要刪除嗎?</strong>",
        type: "warning",
        html: `
          <div class="d-block">
            <div class="my-3">
              <div>商品名稱：${this.product.title}</div>
            </div>
            <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
          </div>
        `,
        confirmButtonText: "確定刪除",
        cancelButtonText: "不刪除",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then(async (result) => {
        if (!result.value) {
          return;
        }

        try {
          await groupbuyApi.deleteGroupbuyingsByProductForce(
            this.currentProductId
          );
          this.$swal.fire({
            icon: "success",
            text: "刪除商品成功",
          }).then(() => {
            window.location.reload();
          });
        } catch (err) {
          const message = err.response.data.message
          if (message.includes("No query results for model")) {
            this.$swal("失敗", '查無此商品', "error").then(() => {
              window.location.reload();
            });
          } else {
            this.$swal("失敗", err.response.data.message, "error");
          }
        }
      });
    },
    async updateProductInfo(product) {
      this.isFetchProductInfo = true
      this.showProductInfo = false
      const { data } = await groupbuyApi.syncStoreGroupBuyProducts({
        sku_serial_number: product.skus[0].sku_serial_number,
        branch_id: this.form.branch_id,
      });

      this.productInfo.id = data.id;
      this.productInfo.name = data.name;
      this.productInfo.barcode = data.poya_prod;
      this.productInfo.ian = data.prod;
      this.productInfo.orig_price = data.orig_price;
      this.productInfo.discount = data.combi_type;

      if (data.mark == 'Y' && parseInt(data.combi_qty) > 1) {
        if (data.combi_type == '' || data.combi_type == null) {
          this.canClick = false;
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "商品價格資訊異常，請掃描其他商品",
          });
        } else {
          this.productInfo.sale_price = data.combi_type;
        }
      } else {
        this.productInfo.sale_price = data.sale_price;
      }
      this.isFetchProductInfo = false
      this.showProductInfo = true
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control,
::v-deep .custom-control-label {
  width: 100%;
}

.buttons__action {
  margin-bottom: 150px;
}

@media screen and (max-width: 768px) {

  .buttons__action > button {
    flex: 1;
    line-height: 1.2;
  }
  .invalid-wording-right {
    width: 320%;
    text-align: end;
    float:right
  }
}
</style>
